import startCase from 'lodash.startcase';

export const stringToHumanReadable = (value) => startCase(value.toLowerCase());

export const isJsonString = (stringToEvaluate) => {
  try {
    JSON.parse(stringToEvaluate);
  } catch (e) {
    return false;
  }
  return true;
};
