import { useCallback, useEffect, useRef, useState } from 'react';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import {
  MdOutlineCalculate,
  MdOutlineDifference,
  MdOutlineLibraryAddCheck,
  MdOutlineNoteAlt,
  MdOutlineTextSnippet,
  MdPersonOutline,
} from 'react-icons/md';
import {
  useBeforeUnload,
  useBlocker,
  useLoaderData,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from 'react-router-dom';
import { useNotesQuery } from 'hooks/services';
import {
  Decision,
  FormAnswer,
  LazyNote,
  Note,
  TestApplication,
  TestCycle,
  ReviewStatus,
  ApplicationTypes,
  SubmissionStatus,
  RootForm,
  User,
} from 'models';
import { DataStore, RecursiveModelPredicate } from 'aws-amplify/datastore';
import { getEditorStateWithFilesInBucket } from 'utils/lexicalEditor';
import { uploadData } from 'aws-amplify/storage';
import { get, post } from 'aws-amplify/api';
import { v4 } from 'uuid';
import { FileNode } from 'components/LexicalEditor/nodes/FileNode';
import { ImageNode } from 'components/LexicalEditor/nodes/ImageNode';
import GoBack from 'components/GoBack';
import { removeFiles } from 'utils/files';
import { EditorState } from 'lexical';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Loading from 'components/Loading';
import { usePostHog } from 'posthog-js/react';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import JSZIP from 'jszip';
import { saveAs } from 'file-saver';
import { flattenObject, getValueFromPath } from 'utils/objects';
import useHabitat from 'hooks/utils/useHabitat';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import CustomButton from 'components/CustomButton';
import style from './AffiliateApplicationDetailsPage.module.css';
import LocalNavigation from './components/LocalNavigation';
import ApplicationTab from './components/ApplicationTab';
import NotesTab from './components/NotesTab';
import DecisionsTab from './components/DecisionsTab';
import CalculationsTab from './components/Calculations';
import { TDecideSchema } from './AffiliateApplicationDetailsPage.schema';
import Buttons from './components/Buttons';
import ApplicantTab from './components/ApplicantTab';

const s3client = new S3Client({
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_PUBLIC_S3_IDKEY || '',
    secretAccessKey: process.env.REACT_APP_PUBLIC_S3_SECRETKEY || '',
  },
});

const AffiliateApplicationDetailsPage = () => {
  const { cycle, formSchema, formUrl } = useRouteLoaderData('cycle') as {
    cycle: TestCycle;
    formSchema: object;
    formUrl: string;
  };

  const {
    application,
    formAnswers,
    decisions,
    user: dataStoreUser,
    attributes,
  } = useLoaderData() as {
    application: TestApplication;
    formAnswers: FormAnswer[];
    decisions: Decision[];
    user?: User;
    attributes?: { attributes: { Name: string; Value: string }[] };
  };

  const posthog = usePostHog();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(
    application.type === 'ONLINE' ? 0 : 3
  );
  const [triggerNotes, setTriggerNotes] = useState(false);
  const [loading, setLoading] = useState(0);
  const [decideModalOpen, setDecideModalOpen] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [uploadingNote, setUploadingNote] = useState(false);
  const [deletingNote, setDeletingNote] = useState(false);
  const [downloadingFiles, setDownloadingFiles] = useState(0);
  const [reviseOpen, setReviseOpen] = useState(false);
  const [updatingRevision, setUpdatingRevision] = useState(false);
  const [leaveRevisionConfirmation, setLeaveRevisionConfirmation] =
    useState<number>();
  const revalidator = useRevalidator();

  const posthogHasBeenNotified = useRef(false);

  const navigate = useNavigate();

  const { habitat } = useHabitat();

  const { user } = useAuthenticator((context) => [context.user]);

  const { data: notes }: { data: Note[] } = useNotesQuery({
    criteria: (c: RecursiveModelPredicate<LazyNote>) =>
      c.testapplicationID.eq(application.id),
    dependencyArray: [application, triggerNotes],
    paginationProducer: undefined,
  });

  const triggerNotesRefetch = () =>
    setTriggerNotes((prevTriggerNotes) => !prevTriggerNotes);

  const uploadDecisionFile = async (file: File) => {
    const result = await uploadData({
      path: `public/decision/${habitat?.urlName}/${application.id}/${v4()}_${
        file.name
      }`,
      data: file,
    }).result;

    return result;
  };

  const handleDecideOnClick = () => setDecideModalOpen(true);

  const handleDecideModalOnClose = () =>
    loading === 0 && setDecideModalOpen(false);

  const handleOnValidDecide = async (data: TDecideSchema) => {
    setLoading((previousLoading) => previousLoading + 1);
    try {
      const original = await DataStore.query(TestApplication, application.id);

      if (!original) {
        return;
      }

      const persistedApplication = await DataStore.save(
        TestApplication.copyOf(original, (originalApplication) => {
          originalApplication.reviewStatus = data.status;
          if (data.status === ReviewStatus.RETURNED && original.ownerID) {
            originalApplication.submissionStatus = SubmissionStatus.INCOMPLETE;
            originalApplication.version =
              (originalApplication.version || 0) + 1;
          }
        })
      );

      const editorStateWithFilesInS3 = await getEditorStateWithFilesInBucket(
        data.message,
        uploadDecisionFile
      );

      await DataStore.save(
        new Decision({
          testapplicationID: application.id,
          status: data.status,
          serializedEditorState: JSON.stringify(editorStateWithFilesInS3),
        })
      );

      let type = '';

      if (data.status === ReviewStatus.ACCEPTED) {
        type = 'application_accepted';
      } else if (data.status === ReviewStatus.DENIED) {
        type = 'application_denied';
      } else if (data.status === ReviewStatus.RETURNED) {
        type = 'application_returned';
        await DataStore.delete(FormAnswer, (c) =>
          c.and((c) => [
            c.testapplicationID.eq(application.id),
            c.isCopy.eq(true),
          ])
        );
      } else {
        type = 'application_pending';
      }

      posthog?.capture(type, {
        data,
        application,
        habitat,
        cycle,
        posthogAction: 'application_reviewed',
      });

      if (original.ownerID) {
        const applicationLink = `${window.origin}/${habitat?.urlName}/applicant/${cycle.id}`;
        await post({
          apiName: 'sendEmailToApplicantAPI',
          path: '/notify',
          options: {
            body: {
              subject: 'Status update on your Habitat for Humanity application',
              body: `<p>A decision has been made on your recent Habitat for Humanity application. Please click on the link below to see it.<br/><br/><a href="${applicationLink}">Application link</a></p>`,
              sub: persistedApplication.ownerID || '',
              habitat: habitat?.longName || '',
            },
          },
        }).response;
      }

      setDecideModalOpen(false);

      navigate('..');
    } catch (error) {
      console.log('An error ocurred while returning the application');
    }
    setLoading((previousLoading) => previousLoading - 1);
  };

  const handleNoteOpenClose = () => {
    if (!uploadingNote) {
      setNoteModal((prevNoteModal) => !prevNoteModal);
    }
  };

  const deleteFilesOfNote = async (note: Note) => {
    const editorState = JSON.parse(note.serializedEditorState);
    const s3Keys = [];
    for (const children of editorState.root.children) {
      if (
        children.type === FileNode.getType() ||
        (children.type === ImageNode.getType() && children.s3Key)
      ) {
        s3Keys.push(children.s3Key);
      }
    }
    await removeFiles(s3Keys);
  };

  const handleDeleteNote = async (note: Note) => {
    try {
      setDeletingNote(true);
      await deleteFilesOfNote(note);
      await DataStore.delete(Note, note.id);
    } catch (error) {
      console.log('Error deleting note', error);
    } finally {
      setDeletingNote(false);
      triggerNotesRefetch();
    }
  };

  const uploadNoteFile = async (file: File) => {
    const result = await uploadData({
      path: `public/notes/${habitat?.urlName}/${application.id}/${v4()}_${
        file.name
      }`,
      data: file,
    }).result;

    return result;
  };

  const handleOnSaveNote = async (editorState: EditorState) => {
    try {
      if (!user || !user.username) {
        return;
      }

      setUploadingNote(true);
      const editorStateWithS3Keys = await getEditorStateWithFilesInBucket(
        editorState,
        uploadNoteFile
      );
      const serializedEditorState = JSON.stringify(editorStateWithS3Keys);

      const newNote = new Note({
        ownerID: user.username,
        serializedEditorState,
        testapplicationID: application.id,
      });

      await DataStore.save(newNote);

      handleNoteOpenClose();
    } catch (error) {
      console.log('Error saving note', error);
    } finally {
      triggerNotesRefetch();
      setUploadingNote(false);
    }
  };

  const handleDownloadApplication = async () => {
    try {
      setDownloadingFiles((prevDownloadingFiles) => prevDownloadingFiles + 1);

      const zip = new JSZIP();

      const pdfBase64Response = await get({
        apiName: 'habitat',
        path: `/application-pdf`,
        options: {
          headers: {
            Accept: 'application/pdf',
          },
          queryParams: {
            applicationId: application.id,
            language: 'en',
          },
        },
      }).response;

      const pdfBlob = await pdfBase64Response.body.blob();

      zip.file('Application.pdf', pdfBlob);

      for (const formAnswer of formAnswers) {
        const { values } = formAnswer;

        const flatValues = flattenObject(values);

        const fileValuesPath = flatValues.filter((flatValue) =>
          flatValue.path.endsWith('.originalName')
        );

        if (fileValuesPath.length > 0 && values) {
          for (const fileValuePath of fileValuesPath) {
            const path = fileValuePath.path.replace('.originalName', '');

            const fileValue = getValueFromPath(
              values as unknown as object,
              path
            );

            const { key, bucket } = fileValue as {
              key: string;
              bucket: string;
            };

            const s3Name = key.split('/').at(-1);

            const command = new GetObjectCommand({
              Bucket: bucket,
              Key: key,
            });

            const response = await s3client.send(command);

            const byteArr = await response.Body?.transformToByteArray();

            if (!byteArr) {
              return;
            }

            zip.file(`files/${s3Name}`, byteArr);
          }
        }
      }

      const rootForm = await DataStore.query(RootForm, cycle?.rootformID);

      const userData = await DataStore.query(User, (c) =>
        c.owner.eq(application?.ownerID || '')
      );

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(
          content,
          `${rootForm?.name}-${cycle?.name}-${userData[0].firstName} ${userData[0].lastName}.zip`
        );
      });
    } catch (error) {
      console.log('Error downloading files', error);
    } finally {
      setDownloadingFiles((prevDownloadingFiles) => prevDownloadingFiles - 1);
    }
  };

  const handleReviseClick = () => setReviseOpen(true);

  const handleReviseCreate = async () => {
    try {
      const original = await DataStore.query(TestApplication, application.id);

      if (original) {
        await DataStore.save(
          TestApplication.copyOf(original, (copy) => {
            copy.hasRevision = true;
          })
        );
      }
    } catch (error) {
      console.log('Error creating revision.');
    }
    setReviseOpen(false);

    setActiveTab(2);

    revalidator.revalidate();
  };

  const handleReviseDisable = async () => {
    try {
      const original = await DataStore.query(TestApplication, application.id);

      if (original) {
        await DataStore.save(
          TestApplication.copyOf(original, (copy) => {
            copy.hasRevision = false;
          })
        );

        await DataStore.delete(FormAnswer, (c) =>
          c.and((c) => [
            c.testapplicationID.eq(application.id),
            c.isCopy.eq(true),
          ])
        );
      }
    } catch (error) {
      console.log('Error disabling revision.');
    }
    setReviseOpen(false);

    setActiveTab(3);

    revalidator.revalidate();
  };

  const handleReviseClose = () => setReviseOpen(false);

  const handleReviseUpdate = async (submission: unknown) => {
    setUpdatingRevision(true);
    try {
      if (
        submission &&
        typeof submission === 'object' &&
        'data' in submission &&
        submission.data &&
        typeof submission.data === 'object'
      ) {
        for (const [key, value] of Object.entries(submission.data)) {
          const originalFormAnswer = await DataStore.query(FormAnswer, (c) =>
            c.and((c) => [
              c.page.eq(key),
              c.isCopy.eq(true),
              c.testapplicationID.eq(application.id),
              c.version.eq(application.version),
            ])
          );

          if (originalFormAnswer.length > 0) {
            await DataStore.save(
              FormAnswer.copyOf(originalFormAnswer[0], (originalFormAnswer) => {
                originalFormAnswer.values = value;
              })
            );
          } else {
            await DataStore.save(
              new FormAnswer({
                page: key,
                values: value,
                testapplicationID: application.id,
                isCopy: true,
                version: application.version,
              })
            );
          }
        }
      }
    } catch (error) {
      console.log('error updating revision', error);
    }

    setUpdatingRevision(false);

    setActiveTab(5);

    revalidator.revalidate();
  };

  useEffect(() => {
    if (
      !posthogHasBeenNotified.current &&
      application &&
      cycle &&
      habitat &&
      posthog
    ) {
      posthog.capture('application_opened', {
        application,
        habitat,
        cycle,
      });
      posthogHasBeenNotified.current = true;
    }
  }, [application, cycle, habitat, posthog]);

  const handleLeavePage = useCallback(() => {
    if (application && cycle && habitat && posthog) {
      posthog.capture('application_closed', {
        application,
        habitat,
        cycle,
      });
    }
  }, [application, cycle, habitat, posthog]);

  useBeforeUnload(handleLeavePage);

  useBlocker(() => {
    handleLeavePage();
    return false;
  });

  const blockLeaveRevision = useBlocker(() => activeTab === 2);

  const handleOnLeaveRevision = () => {
    if (blockLeaveRevision.state === 'blocked') {
      blockLeaveRevision.proceed && blockLeaveRevision.proceed();
    } else {
      leaveRevisionConfirmation && setActiveTab(leaveRevisionConfirmation);
      setLeaveRevisionConfirmation(undefined);
    }
  };

  const handleOnStayRevision = () => {
    blockLeaveRevision.reset && blockLeaveRevision.reset();
    setLeaveRevisionConfirmation(undefined);
  };

  if (!cycle) return <Loading />;

  const breadCrumbsItems = [
    { label: t('pages.habitat.affiliate.forms.name'), to: '../../../forms' },
    { label: t('pages.habitat.affiliate.cycles.name'), to: '../..' },
    { label: t('pages.habitat.affiliate.cycles.cycle.name'), to: '..' },
    { label: t('pages.habitat.affiliate.cycles.cycle.application.name') },
  ];

  return (
    <div className={`${style.page}`}>
      <BreadCrumbs items={breadCrumbsItems} />
      <div className={`${style.ctaContainer}`}>
        <div className={`${style.cta}`}>
          <GoBack />
          <span className={`theme-headline-medium ${style.title}`}>
            {t('pages.habitat.affiliate.cycles.cycle.application.title')}
          </span>
        </div>
      </div>
      {application.reviewStatus !== ReviewStatus.RETURNED && (
        <div>
          <Buttons
            application={application}
            decideModalOpen={decideModalOpen}
            handleDecideModalOnClose={handleDecideModalOnClose}
            handleOnValidDecide={handleOnValidDecide}
            handleDecideOnClick={handleDecideOnClick}
            handleDownloadApplicationOnClick={handleDownloadApplication}
            loading={loading}
            downloading={downloadingFiles > 0}
            reviseOpen={reviseOpen}
            handleClickRevise={handleReviseClick}
            handleCloseRevise={handleReviseClose}
            handleCreateRevise={handleReviseCreate}
            handleDisableRevise={handleReviseDisable}
            hasRevision={application?.hasRevision || false}
          />
        </div>
      )}
      <div className={`${style.detailsContainer}`}>
        <LocalNavigation
          items={[
            {
              label: t(
                'pages.habitat.affiliate.cycles.cycle.application.tabs.applicant'
              ),
              icon: <MdPersonOutline />,
              hide: application?.type !== ApplicationTypes.ONLINE,
            },
            {
              label: t(
                'pages.habitat.affiliate.cycles.cycle.application.tabs.submission'
              ),
              icon: <MdOutlineNoteAlt />,
            },
            {
              label: t(
                'pages.habitat.affiliate.cycles.cycle.application.tabs.revision'
              ),
              icon: <MdOutlineDifference />,
              hide:
                !application?.hasRevision ||
                application?.reviewStatus === ReviewStatus.RETURNED,
            },

            {
              label: t(
                'pages.habitat.affiliate.cycles.cycle.application.tabs.notes'
              ),
              icon: <MdOutlineTextSnippet />,
            },
            {
              label: t(
                'pages.habitat.affiliate.cycles.cycle.application.tabs.decisions'
              ),
              icon: <MdOutlineLibraryAddCheck />,
            },
            {
              label: t(
                'pages.habitat.affiliate.cycles.cycle.application.tabs.calculations'
              ),
              icon: <MdOutlineCalculate />,
              hide:
                application?.type !== ApplicationTypes.ONLINE ||
                application?.reviewStatus === ReviewStatus.RETURNED,
            },
          ]}
          current={activeTab}
          onChange={(newCurrent) => {
            if (activeTab !== 2) {
              setActiveTab(newCurrent);
            } else {
              setLeaveRevisionConfirmation(newCurrent);
            }
          }}
        />
        <div className={style.tabContainer}>
          {activeTab === 0 && (
            <ApplicantTab
              user={dataStoreUser}
              attributes={attributes?.attributes}
            />
          )}
          {activeTab === 1 && (
            <ApplicationTab
              application={application}
              cycleFormUrl={cycle?.formUrl}
              formAnswers={formAnswers}
              formUrl={formUrl}
              formSchema={formSchema}
              showPrevious={application.reviewStatus === ReviewStatus.RETURNED}
            />
          )}
          {activeTab === 2 && (
            <>
              <Modal
                title={t(
                  'pages.habitat.affiliate.cycles.cycle.application.confirmLeaveRevisionModal.title'
                )}
                open={
                  blockLeaveRevision.state === 'blocked' ||
                  typeof leaveRevisionConfirmation === 'number'
                }
                onClickClose={handleOnStayRevision}
                width="500px"
              >
                <div className={style.leaveRevisionContent}>
                  <p>
                    {t(
                      'pages.habitat.affiliate.cycles.cycle.application.confirmLeaveRevisionModal.text'
                    )}
                  </p>
                  <div className={style.leaveRevisionContentButtons}>
                    <CustomButton type="button" onClick={handleOnLeaveRevision}>
                      {t(
                        'pages.habitat.affiliate.cycles.cycle.application.confirmLeaveRevisionModal.accept'
                      )}
                    </CustomButton>
                    <CustomButton
                      variation="secondary"
                      type="button"
                      onClick={handleOnStayRevision}
                    >
                      {t(
                        'pages.habitat.affiliate.cycles.cycle.application.confirmLeaveRevisionModal.cancel'
                      )}
                    </CustomButton>
                  </div>
                </div>
              </Modal>
              <ApplicationTab
                application={application}
                cycleFormUrl={cycle?.formUrl}
                formAnswers={formAnswers}
                revision
                handleReviseUpdate={handleReviseUpdate}
                updatingRevision={updatingRevision}
                formUrl={formUrl}
                formSchema={formSchema}
              />
            </>
          )}
          {activeTab === 3 && (
            <NotesTab
              notes={notes}
              noteModal={noteModal}
              uploadingNote={uploadingNote}
              deletingNote={deletingNote}
              handleDeleteNote={handleDeleteNote}
              handleNoteOpenClose={handleNoteOpenClose}
              handleOnSaveNote={handleOnSaveNote}
            />
          )}
          {activeTab === 4 && <DecisionsTab decisions={decisions} />}
          {activeTab === 5 && (
            <CalculationsTab
              formAnswers={formAnswers}
              hasRevision={application.hasRevision || false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AffiliateApplicationDetailsPage;
