import { DataStore } from 'aws-amplify/datastore';
import { RootForm, TestCycle } from 'models';
import { LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

const FORMIO_URL = process.env.REACT_APP_FORMIO_URL;

const cycleLoader: LoaderFunction = async ({ params }) => {
  const { cycleId } = params;

  if (!cycleId) {
    throw new Response('Cycle not specified.', { status: 400 });
  }

  await untilDataStoreIsReady();

  const cycle = await DataStore.query(TestCycle, cycleId);

  if (!cycle) {
    throw new Response('Cycle not found.', { status: 404 });
  }

  const formUrl = `${FORMIO_URL}/${cycle.formUrl}`;

  const formSchemaResponse = await fetch(formUrl);

  if (!formSchemaResponse.ok) {
    throw new Response('Form schema not found.', { status: 404 });
  }

  const formSchema = await formSchemaResponse.json();

  return { cycle, formSchema, formUrl };
};

export const cycleWithRootFormLoader: LoaderFunction = async ({ params }) => {
  const { cycleId } = params;

  if (!cycleId) {
    throw new Response('Cycle not specified.', { status: 400 });
  }

  await untilDataStoreIsReady();

  const cycle = await DataStore.query(TestCycle, cycleId);

  if (!cycle) {
    throw new Response('Cycle not found.', { status: 404 });
  }

  const formUrl = `${FORMIO_URL}/${cycle.formUrl}`;

  const formSchemaResponse = await fetch(formUrl);

  if (!formSchemaResponse.ok) {
    throw new Response('Form schema not found.', { status: 404 });
  }

  const formSchema = await formSchemaResponse.json();

  const rootForm = await DataStore.query(RootForm, cycle.rootformID);

  if (!rootForm) {
    throw new Response('RootForm not found.', { status: 404 });
  }

  return { cycle, rootForm, formSchema, formUrl };
};

export default cycleLoader;
