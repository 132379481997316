const pages = [
  {
    number: 1,
    step: 1,
    section: 'General',
  },
  {
    number: 2,
    step: 2,
    section: 'Affiliate',
  },
  {
    number: 3,
    step: 3,
    section: 'Confirmation',
  },
];

export default pages;
