import { DataStore } from 'aws-amplify/datastore';
import { Habitat } from 'models';
import { LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

const habitatLoader: LoaderFunction = async ({ params }) => {
  const { habitat } = params;

  await untilDataStoreIsReady();

  const habitats = await DataStore.query(Habitat, (c) => c.urlName.eq(habitat));

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  return { habitat: habitats[0] };
};

export default habitatLoader;
