const authenticator = {
  router: {
    borderWidth: { value: '0' },
    boxShadow: { value: 'none' },
  },
  form: {
    padding: { value: '0' },
  },
};

export default authenticator;
