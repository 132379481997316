import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Wizard } from '@formio/react';
import { ApplicationTypes, FormAnswer } from 'models';
import { generateSubmission } from 'utils/formio';
import useAsync from 'hooks/utils/useAsync/useAsync';
import { Status } from 'utils/enums';
import { Options } from '@formio/react/lib/components/Form';
import CustomButton from 'components/CustomButton';
import { isEmpty } from 'lodash';
import { ApplicationTabProps } from './ApplicationTab.types';
import PaperApplicationTable from './components/PaperApplicationTable';
import style from './ApplicationTab.module.css';

const FORMIO_URL = process.env.REACT_APP_FORMIO_URL;

const ApplicationTab = ({
  application,
  formAnswers,
  cycleFormUrl,
  formUrl,
  formSchema,
  revision,
  handleReviseUpdate,
  updatingRevision,
  showPrevious,
}: ApplicationTabProps) => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const [formInstance, setFormInstance] = useState<typeof Wizard>();
  const fetchI18n = useCallback(async (): Promise<{
    [key: string]: unknown;
  }> => {
    const response = await fetch(
      `${FORMIO_URL}/language/submission?data.language=${language}&data.form=${cycleFormUrl}`
    );
    const array = await response.json();

    let translations = {};

    if (array.length > 0) {
      const { data } = array[0];
      const { translation } = data;
      Object.keys(translation).forEach((key) => {
        const newKey = key.replace(/__DOT__/g, '.').replace(/__USD__/g, '$');
        translation[newKey] = translation[key];
        if (newKey !== key) {
          delete translation[key];
        }
      });

      translations = {
        [`${language}`]: translation,
      };
    }
    return translations;
  }, [cycleFormUrl, language]);

  const { value, status } = useAsync({
    asyncFunction: fetchI18n,
  });

  if (status === Status.PENDING) {
    return <div>Loading...</div>;
  }

  if (status === Status.REJECTED) {
    return <div>Error</div>;
  }

  const render = {
    [ApplicationTypes.ONLINE]: (
      <>
        <Form
          key={`review-${language}`}
          url={formUrl}
          form={formSchema}
          options={
            {
              readOnly: !revision,
              renderMode: 'flat',
              language: isEmpty(value) ? 'en' : language,
              i18n: value,
            } as Options
          }
          formReady={(instance: typeof Wizard) => {
            setFormInstance(instance);
          }}
          submission={generateSubmission(
            formAnswers as FormAnswer[],
            showPrevious
              ? (application?.version || 1) - 1
              : application?.version,
            revision
          )}
          onSubmit={handleReviseUpdate}
        />
        {revision && formInstance && (
          <div className={style.buttonContainer}>
            <CustomButton
              onClick={async () => {
                try {
                  await formInstance.submit();
                } catch (error) {
                  console.log('error', error);
                }
              }}
            >
              {t(
                'pages.habitat.affiliate.cycles.cycle.application.components.applicationTab.update'
              )}
            </CustomButton>
          </div>
        )}
      </>
    ),
    [ApplicationTypes.PAPER]: (
      <PaperApplicationTable application={application} />
    ),
    loading: <p>Loading</p>,
  }[updatingRevision ? 'loading' : application?.type || 'loading'];

  return (
    <div className={style.formContainer}>
      <br />
      {render}
    </div>
  );
};

export default ApplicationTab;
