export const generateCSVFromObjectArray = function (data: object[]) {
  const csvRows = [];

  const headers: string[] = Object.keys(data[0]);

  csvRows.push(headers.join(','));

  for (const row of data) {
    const values = headers.map((e) => (row as { [key: string]: unknown })[e]);
    csvRows.push(values.join(','));
  }

  const content = csvRows.join('\n');

  const blob = new Blob([content], { type: 'text/csv' });

  return blob;
};
