import { TestApplication } from 'models';

/* eslint-disable no-shadow */
export const enum DISPLAY {
  LOADING = 'loading',
  ERROR = 'error',
  NO_OPEN_CYCLE = 'noOpenCycle',
  APPLICATION = 'application',
  COMPLETED = 'completed',
  REVIEWED = 'reviewed',
}

export const enum ERROR {
  HABITAT_NOT_FOUND = 'Habitat not found',
  CYCLE_NOT_FOUND = 'Cycle not found',
  CYCLE_NOT_OPEN = 'Cycle not open',
  UNEXPECTED_ERROR = 'Unexpected error',
}

interface DisplayLoadingProps {
  display: DISPLAY.LOADING;
}

interface DisplayErrorProps {
  display: DISPLAY.ERROR;
  data: {
    error: string;
  };
}

interface DisplayApplicationProps {
  display: DISPLAY.APPLICATION;
  data: {
    application: TestApplication;
  };
}

interface DisplayReviewProps {
  display: DISPLAY.REVIEWED;
  data: {
    application: TestApplication;
  };
}

interface DisplayNoOpenCycleProps {
  display: DISPLAY.NO_OPEN_CYCLE;
  data: {
    error: ERROR;
    application?: TestApplication;
  };
}

interface DisplayCompletedProps {
  display: DISPLAY.COMPLETED;
  data: {
    application: TestApplication;
  };
}

export type DataProps =
  | DisplayLoadingProps
  | DisplayErrorProps
  | DisplayApplicationProps
  | DisplayReviewProps
  | DisplayNoOpenCycleProps
  | DisplayCompletedProps;
