import { get } from 'aws-amplify/api';
import { DataStore } from 'aws-amplify/datastore';
import { TestApplication, User } from 'models';
import { LoaderFunction } from 'react-router-dom';
import { untilDataStoreIsReady } from 'utils/dataStore';

const applicationLoader: LoaderFunction = async ({ params }) => {
  const { applicationId } = params;

  if (!applicationId) {
    throw new Response('Application not specified.', { status: 400 });
  }

  await untilDataStoreIsReady();

  const application = await DataStore.query(TestApplication, applicationId);

  if (!application) {
    throw new Response('Application not found.', { status: 404 });
  }

  const formAnswers = await application.FormAnswers.toArray();

  const decisions = await application.Decisions.toArray();

  const user = await DataStore.query(User, (c) =>
    c.owner.eq(application.ownerID || '')
  );

  const attributesResponse = application.ownerID
    ? await get({
        apiName: 'userAPI',
        path: '/',
        options: {
          queryParams: {
            sub: application.ownerID || '',
          },
        },
      }).response
    : undefined;

  const attributes = attributesResponse
    ? await attributesResponse.body.json()
    : undefined;

  return {
    application,
    formAnswers,
    decisions,
    user: user.length > 0 ? user[0] : undefined,
    attributes,
  };
};

export default applicationLoader;
