import { DataStore } from 'aws-amplify/datastore';
import { Hub } from 'aws-amplify/utils';

export const untilDataStoreIsReady = async () => {
  await DataStore.stop();
  await DataStore.start();

  const DataStorePromise = new Promise<void>((resolve, reject) => {
    try {
      const listener = Hub.listen('datastore', async (hubData) => {
        const { event } = hubData.payload;
        if (event === 'ready') {
          listener();
          resolve();
        }
      });
    } catch (error) {
      reject(error);
    }
  });

  return DataStorePromise;
};
