import { useForm, SubmitHandler } from 'react-hook-form';
import { MdArrowDropDown } from 'react-icons/md';
import { throttle } from 'lodash';

import Footer from 'components/Footer';

import styles from '../SignUpQuestions.module.css';
import dataProps from '../types';
import Explanation from '../Explanation/Explanation';

const industries = [
  'Information Technology (IT)',
  'Healthcare and Medical',
  'Finance and Banking',
  'Education',
  'Manufacturing',
  'Retail',
  'Hospitality and Tourism',
  'Construction',
  'Transportation and Logistics',
  'Real Estate',
  'Telecommunications',
  'Energy and Utilities',
  'Legal Services',
  'Government and Public Administration',
  'Media and Entertainment',
  'Agriculture and Farming',
  'Nonprofit and Social Services',
  'Automotive',
  'Pharmaceuticals and Biotechnology',
  'Aerospace and Defense',
  'Food and Beverage',
  'Fashion and Apparel',
  'Environmental Services',
  'Arts and Design',
  'Insurance',
  'Mining and Extraction',
  'Consumer Goods',
  'Consulting',
  'Human Resources',
  'Research and Development (R&D)',
  'Other',
] as const;

export type TIndustry = (typeof industries)[number];

interface Inputs {
  unemployed: 'Yes' | 'No';
  position: string;
  employer: string;
  industry: TIndustry;
  otherIndustry: string;
}

interface EmploymentProps {
  data: dataProps;
  setData: React.Dispatch<React.SetStateAction<dataProps>>;
  goBack: () => void;
}

const Employment = ({ data, setData, goBack }: EmploymentProps) => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const watchUnemployed = watch('unemployed') || data?.employment?.unemployed;

  const watchIndustry = watch('industry') || data?.employment?.industry;

  const onSubmit: SubmitHandler<Inputs> = (employmentData) => {
    setData((prev) => ({
      ...prev,
      current: prev.current + 1,
      employment: employmentData,
    }));
  };

  return (
    <form
      className={styles.background}
      onSubmit={throttle(handleSubmit(onSubmit), 500)}
    >
      <div className={styles.body}>
        <Explanation />
        <div>
          <label
            htmlFor="unemployed"
            className={`theme-body-medium ${styles.label}`}
          >
            Are you currently unemployed?
          </label>
          <div>
            <div className={styles.select}>
              <select
                id="unemployed"
                className={styles.select_input}
                defaultValue={data?.employment?.unemployed || 'Yes'}
                {...register('unemployed', { required: true })}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <MdArrowDropDown size="1.5rem" className={styles.arrow} />
            </div>
            {errors.unemployed && (
              <span className={`${styles.error} theme-body-small`}>
                This field is required
              </span>
            )}
          </div>
        </div>
        {watchUnemployed === 'No' && (
          <>
            <div>
              <label
                htmlFor="position"
                className={`theme-body-medium ${styles.label}`}
              >
                What is your current work title?
              </label>
              <div>
                <input
                  id="position"
                  type="string"
                  placeholder="Assistant Manager"
                  defaultValue={data?.employment?.position || ''}
                  className={`theme-body-medium ${styles.number_input}`}
                  {...register('position', { required: true })}
                />
                {errors.position && (
                  <span className={`${styles.error} theme-body-small`}>
                    This field is required
                  </span>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="employer"
                className={`theme-body-medium ${styles.label}`}
              >
                What is the name of your employer?
              </label>
              <div>
                <input
                  id="employer"
                  type="string"
                  placeholder="Hilton Hotel"
                  defaultValue={data?.employment?.employer || ''}
                  className={`theme-body-medium ${styles.number_input}`}
                  {...register('employer', { required: true })}
                />
                {errors.employer && (
                  <span className={`${styles.error} theme-body-small`}>
                    This field is required
                  </span>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="industry"
                className={`theme-body-medium ${styles.label}`}
              >
                Industry?
              </label>
              <div>
                <div className={styles.select}>
                  <select
                    id="industry"
                    className={styles.select_input}
                    defaultValue={data?.employment?.industry || industries[0]}
                    {...register('industry', { required: true })}
                  >
                    {industries.map((industry) => (
                      <option key={industry} value={industry}>
                        {industry}
                      </option>
                    ))}
                  </select>
                  <MdArrowDropDown size="1.5rem" className={styles.arrow} />
                </div>
                {errors.industry && (
                  <span className={`${styles.error} theme-body-small`}>
                    This field is required
                  </span>
                )}
              </div>
            </div>
            {watchIndustry === 'Other' && (
              <div>
                <label
                  htmlFor="otherIndustry"
                  className={`theme-body-medium ${styles.label}`}
                >
                  Please, explain:
                </label>
                <div>
                  <input
                    id="otherIndustry"
                    type="text"
                    defaultValue={data?.employment?.otherIndustry || ''}
                    className={`theme-body-medium ${styles.number_input}`}
                    {...register('otherIndustry', { required: true })}
                  />
                  {errors.otherIndustry && (
                    <span className={`${styles.error} theme-body-small`}>
                      This field is required
                    </span>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Footer goBack={goBack} />
    </form>
  );
};

export default Employment;
