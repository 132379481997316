import React from 'react';
import ExpandableCard from 'components/ExpandableCard';
import { User } from 'models';
import { useTranslation } from 'react-i18next';
import styles from './ApplicantTab.module.css';

interface IApplicantTabProps {
  user?: User;
  attributes?: { Name: string; Value: string }[];
}

const ApplicantTab = ({ user, attributes }: IApplicantTabProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <ExpandableCard>
        <div className={styles.content}>
          <span className={styles.title}>
            {t(
              'pages.habitat.affiliate.cycles.cycle.application.components.applicantTab.name'
            )}
          </span>
          <br />
          <span>{`${user?.firstName} ${user?.lastName}`}</span>
        </div>
      </ExpandableCard>
      <ExpandableCard>
        <div className={styles.content}>
          <span className={styles.title}>
            {t(
              'pages.habitat.affiliate.cycles.cycle.application.components.applicantTab.email'
            )}
          </span>
          <br />
          <span>{`${
            attributes?.find((attribute) => attribute.Name === 'email')?.Value
          }`}</span>
        </div>
      </ExpandableCard>
      <ExpandableCard>
        <div className={styles.content}>
          <span className={styles.title}>
            {t(
              'pages.habitat.affiliate.cycles.cycle.application.components.applicantTab.phone'
            )}
          </span>
          <br />
          <span>{`${
            attributes?.find((attribute) => attribute.Name === 'phone_number')
              ?.Value
          }`}</span>
        </div>
      </ExpandableCard>
    </div>
  );
};

export default ApplicantTab;
