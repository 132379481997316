import { MdArrowBack, MdClose, MdHelpOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Button } from '@aws-amplify/ui-react';
import IconButton from 'components/IconButton';
import { useState } from 'react';
import LexicalEditor from 'components/LexicalEditor';
import { isJsonString } from 'utils/strings';
import styles from './Footer.module.css';
import FooterProps from './Footer.types';

const Footer = ({
  goBack,
  onNext,
  submit = false,
  serializedHelpContent,
}: FooterProps) => {
  const { t } = useTranslation();

  const [showHelp, setShowHelp] = useState(false);

  const classname = {
    background: `${styles.background} ${
      !goBack && styles.background_without_back
    }`,
    back: goBack ? styles.previous : styles.no_interaction,
    next: `${styles.next} ${!goBack && styles.next_without_back}`,
    help: `${styles.help_icon} ${!goBack && styles.help_icon_without_back}`,
  };

  const nextText = submit
    ? t('components.footer.review')
    : t('components.footer.continue');

  return (
    <div className={classname.background}>
      <Button variation="link" className={classname.back} onClick={goBack}>
        {goBack && (
          <>
            <MdArrowBack
              className={styles.hide_on_small}
              size="24px"
              style={{ marginRight: '1rem' }}
            />
            {t('components.footer.goBack')}
          </>
        )}
      </Button>
      <Button
        onClick={onNext}
        type="submit"
        variation="primary"
        className={classname.next}
      >
        {nextText}
      </Button>
      {serializedHelpContent && isJsonString(serializedHelpContent) && (
        <div className={classname.help}>
          {showHelp && (
            <div className={styles.helpCard}>
              <div className={styles.helpCardHeader}>
                <span>{t('components.footer.help')}</span>
                <IconButton
                  variation="not-outlined"
                  onClick={() => setShowHelp((prevHelp) => !prevHelp)}
                  className={styles.helpCardCloseButton}
                >
                  <MdClose className={styles.helpCardClose} />
                </IconButton>
              </div>
              <div className={styles.helpCardContent}>
                <LexicalEditor serializedEditorState={serializedHelpContent} />
              </div>
            </div>
          )}
          <IconButton onClick={() => setShowHelp((prevHelp) => !prevHelp)}>
            <MdHelpOutline />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default Footer;
