import { FormAnswer } from 'models';
import { cloneDeep } from 'lodash';

interface ISubmission {
  data: {
    [key: string]: unknown;
  };
}

export const generateSubmission = (
  formAnswers: FormAnswer[],
  version: number | null | undefined,
  isRevision = false
): ISubmission => {
  const submissionData: ISubmission['data'] = {};

  const copies = formAnswers
    .filter((formAnswer) => formAnswer.isCopy)
    .sort((a, b) => (a.version || 0) - (b.version || 0));

  const originals = formAnswers
    .filter((formAnswer) => !formAnswer.isCopy)
    .sort((a, b) => (a.version || 0) - (b.version || 0));

  for (const formAnswer of originals) {
    if (formAnswer.page && formAnswer.values) {
      if (
        isRevision &&
        copies.map((copy) => copy.page).includes(formAnswer.page)
      ) {
        const revision = copies.find((copy) => copy.page === formAnswer.page);

        if (
          revision &&
          revision.values &&
          (revision.version || 0) <= (version || 0)
        ) {
          submissionData[formAnswer.page] = cloneDeep(revision.values);
        }
      } else if ((formAnswer.version || 0) <= (version || 0)) {
        submissionData[formAnswer.page] = cloneDeep(formAnswer.values);
      }
    }
  }

  const submission = {
    data: submissionData,
  };

  return submission;
};
