import Modal from 'components/Modal';
import { Flex, Text } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton';

interface IReviseModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
  onDisable: () => void;
  hasRevision: boolean;
}

const ReviseModal = ({
  open,
  onClose,
  onCreate,
  onDisable,
  hasRevision,
}: IReviseModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t(
        'pages.habitat.affiliate.cycles.cycle.application.components.buttons.components.reviseModal.title'
      )}
      open={open}
      onClickClose={onClose}
      width="45rem"
    >
      <Text>
        {t(
          `pages.habitat.affiliate.cycles.cycle.application.components.buttons.components.reviseModal.description${
            hasRevision ? '2' : '1'
          }`
        )}
      </Text>
      <br />
      <Text>
        {t(
          `pages.habitat.affiliate.cycles.cycle.application.components.buttons.components.reviseModal.confirmQuestion${
            hasRevision ? '2' : '1'
          }`
        )}
      </Text>
      <br />
      <Flex justifyContent="end" marginTop="1rem">
        <CustomButton variation="secondary" onClick={onClose}>
          {t(
            'pages.habitat.affiliate.cycles.cycle.application.components.buttons.components.reviseModal.cancel'
          )}
        </CustomButton>
        {hasRevision ? (
          <CustomButton type="button" onClick={onDisable}>
            {t(
              'pages.habitat.affiliate.cycles.cycle.application.components.buttons.components.reviseModal.disable'
            )}
          </CustomButton>
        ) : (
          <CustomButton type="button" onClick={onCreate}>
            {t(
              'pages.habitat.affiliate.cycles.cycle.application.components.buttons.components.reviseModal.create'
            )}
          </CustomButton>
        )}
      </Flex>
    </Modal>
  );
};

export default ReviseModal;
