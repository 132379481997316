import { PricesProps } from '../types';

const prices: PricesProps = [
  {
    id: '2',
    titleBlack: '$0',
    titleGray: '/month',
    message: '1 month free trial',
    body: [
      { id: '1', type: 'include', text: 'Free set up for your affiliate' },
      { id: '2', type: 'include', text: 'We build your online forms' },
      { id: '3', type: 'include', text: 'Test out what applicants see' },
      { id: '4', type: 'include', text: 'Request changes' },
      { id: '5', type: 'include', text: 'Access to new features' },
    ],
    button: 'Try For Free',
  },
  {
    id: '2',
    titleBlack: 'Custom Pricing',
    titleGray: '',
    message: 'Contact us',
    body: [
      { id: '1', type: 'include', text: 'Custom program forms' },
      { id: '2', type: 'include', text: 'Unlimited application cycles' },
      { id: '3', type: 'include', text: 'Unlimited users' },
      { id: '4', type: 'include', text: 'Direct customer support' },
      { id: '5', type: 'include', text: 'Access to new features' },
    ],
    button: 'Contact Us',
  },
];

export default prices;
