/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import {
  DataStore,
  RecursiveModelPredicate,
  SortDirection,
  SortPredicate,
} from 'aws-amplify/datastore';
import CustomButton from 'components/CustomButton/CustomButton';
import TableWithPaginator from 'components/TableWithPaginator';
import { useTestApplicationsQuery } from 'hooks/services';
import {
  SubmissionStatus,
  LazyTestApplication,
  TestApplication,
  ApplicationTypes,
  TestCycle,
  RootForm,
  User,
  ReviewStatus,
} from 'models';
import { useState } from 'react';
import {
  MdOutlineAdd,
  MdOutlineBuild,
  MdOutlineFilterList,
  MdOutlineLink,
  MdOutlineOpenInNew,
  MdOutlineSaveAlt,
  MdOutlineScience,
} from 'react-icons/md';
import {
  Link,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from 'react-router-dom';
import { stringToHumanReadable } from 'utils/strings';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import DropdownMenu from 'components/DropdownMenu';
import GoBack from 'components/GoBack';
import { DEFAULT_REVIEW_STATUS } from 'utils/constants';
import {
  Flex,
  Loader,
  Menu,
  MenuButton,
  MenuItem,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import { convertDateYYYYMMDDtoDDMMYYYY } from 'utils/dates';
import StatusChip from 'components/StatusChip';
import useHabitat from 'hooks/utils/useHabitat';
import ResultsCounter from 'components/ResultsCounter';
import { get } from 'aws-amplify/api';
import { generateCSVFromObjectArray } from 'utils/csv';
import { downloadWithUrl } from 'utils/files';
import style from './AffiliateCycleApplications.module.css';
import NewApplicationModal from './components/NewApplicationModal';
import StatusModal from './components/StatusModal';
import { Inputs } from './types';
import Filters from './components/Filters';
import Username from './components/Username';
import { handleCopyToClipboard } from './utils';
import ApplicationLinkModal from './components/ApplicationLinkModal';

const AffiliateCycleApplications = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const isSmall = useBreakpointValue({
    base: true,
    medium: false,
  });

  const { cycle } = useRouteLoaderData('cycle') as { cycle: TestCycle };

  const { rootForm } = useRouteLoaderData('rootForm') as { rootForm: RootForm };

  const { habitat } = useHabitat();

  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const [newApplicationOpen, setNewApplicationOpen] = useState(false);

  const [trigger, setTrigger] = useState(0);

  const [filterModal, setFilterModal] = useState(false);

  const [filters, setFilters] = useState<Inputs>({
    startDateSubmitted: '',
    endDateSubmitted: '',
    type: null,
    reviewStatus: null,
    customStatus: '',
  });

  const [linkModal, setLinkModal] = useState(false);

  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const { data: applications }: { data: TestApplication[] } =
    useTestApplicationsQuery({
      criteria: (c1: RecursiveModelPredicate<LazyTestApplication>) =>
        c1.and((c2) => {
          let criteriaArr = [c2.testcycleID.eq(cycle.id)];

          if (filters.reviewStatus) {
            criteriaArr = [
              ...criteriaArr,
              c2.reviewStatus.eq(filters.reviewStatus),
            ];
          }

          if (
            filters.startDateSubmitted &&
            filters.startDateSubmitted !== 'MM/DD/YYYY'
          ) {
            criteriaArr = [
              ...criteriaArr,
              c2.submittedDate.ge(filters.startDateSubmitted),
            ];
          }

          if (
            filters.endDateSubmitted &&
            filters.endDateSubmitted !== 'MM/DD/YYYY'
          ) {
            criteriaArr = [
              ...criteriaArr,
              c2.submittedDate.le(filters.endDateSubmitted),
            ];
          }

          if (filters.type) {
            criteriaArr = [...criteriaArr, c2.type.eq(filters.type)];
          }

          if (filters.customStatus === DEFAULT_REVIEW_STATUS) {
            criteriaArr = [
              ...criteriaArr,
              c2.or((c3) => [
                c3.customStatus.eq(filters.customStatus),
                c3.customStatus.eq(null),
              ]),
            ];
          } else if (filters.customStatus) {
            criteriaArr = [
              ...criteriaArr,
              c2.customStatus.eq(filters.customStatus),
            ];
          }

          return criteriaArr;
        }),
      paginationProducer: {
        sort: (s: SortPredicate<LazyTestApplication>) =>
          s.submittedDate(SortDirection.DESCENDING),
      },
      dependencyArray: [cycle, trigger, filters],
    });

  let applicationsCompleted: TestApplication[] = [];
  let applicationsPending: TestApplication[] = [];
  let applicationsFiltered: TestApplication[] = [];

  if (applications) {
    applicationsCompleted = applications.filter(
      (application) =>
        application.submissionStatus === SubmissionStatus.COMPLETED ||
        application.reviewStatus === ReviewStatus.RETURNED
    );

    applicationsPending = applications.filter(
      (application) =>
        application.submissionStatus === SubmissionStatus.INCOMPLETE &&
        application.reviewStatus !== ReviewStatus.RETURNED
    );

    applicationsFiltered = applications.filter(
      (application) => application.filtered
    );
  }

  const handleUpdateApplicationStatus = async (
    applicationId: string,
    newStatusValue: string
  ) => {
    try {
      const original = await DataStore.query(TestApplication, applicationId);
      if (!original) return;
      await DataStore.save(
        TestApplication.copyOf(original, (originalApplication) => {
          originalApplication.customStatus = newStatusValue;
        })
      );
      setTrigger((previousTrigger) => previousTrigger + 1);
    } catch (error) {
      console.log('Error while updating the status');
    }
  };

  const handleStatusOnClick = () => setStatusModalOpen(true);
  const handleOnCloseStatusModal = () => setStatusModalOpen(false);

  const handleAddNewApplicationOnClick = () => setNewApplicationOpen(true);
  const handleOnCloseNewApplicationModal = () => setNewApplicationOpen(false);

  const breadCrumbsItems = [
    { label: t('pages.habitat.affiliate.forms.name'), to: '../../forms' },
    { label: t('pages.habitat.affiliate.cycles.name'), to: '..' },
    { label: t('pages.habitat.affiliate.cycles.cycle.name') },
  ];

  const handleDownloadIncompleteCSV = async () => {
    try {
      setDownloadingCSV(true);

      const namesAndEmail: {
        name: string;
        email: string;
        phone: string;
        status: string;
      }[] = [];

      for (const application of applicationsPending) {
        const user = await DataStore.query(User, (c) =>
          c.owner.eq(application.ownerID || '')
        );

        if (user[0]) {
          const response = await get({
            apiName: 'userAPI',
            path: `/`,
            options: {
              queryParams: {
                sub: user[0].owner,
              },
            },
          }).response;

          try {
            const attributes = await response.body.json();

            if (attributes) {
              const getStatus = (application: TestApplication) => {
                if (application.reviewStatus === ReviewStatus.RETURNED) {
                  return 'Returned';
                }
                if (application.filtered) {
                  return 'Filtered';
                }
                return 'Incomplete';
              };

              namesAndEmail.push({
                name: `${user[0].firstName} ${user[0].lastName}`,
                email:
                  (
                    attributes as {
                      attributes: { Name: string; Value: string }[];
                    }
                  ).attributes.find((attribute) => attribute.Name === 'email')
                    ?.Value || '',
                phone:
                  (
                    attributes as {
                      attributes: { Name: string; Value: string }[];
                    }
                  ).attributes.find(
                    (attribute) => attribute.Name === 'phone_number'
                  )?.Value || '',
                status: getStatus(application),
              });
            }
          } catch (error) {
            console.log('User attributes not found.');
          }
        }
      }

      const csvFile = generateCSVFromObjectArray(namesAndEmail);

      const csvUrl = URL.createObjectURL(csvFile);

      downloadWithUrl(
        csvUrl,
        `${
          habitat?.longName
        }-Incomplete Applications-${new Date().toUTCString()}.csv`
      );
    } catch (error) {
      console.log('Error while downloading the CSV file.');
    } finally {
      setDownloadingCSV(false);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.firstRow}>
        {!isSmall && <BreadCrumbs items={breadCrumbsItems} />}
        <div className={style.nonCompleteApplicationsContainer}>
          <p className={`theme-body-medium ${style.incompleteApplications}`}>
            {t('pages.habitat.affiliate.cycles.cycle.incompleteApplications')}{' '}
            {applicationsPending.length}
          </p>
          <p className={`theme-body-medium ${style.filteredApplications}`}>
            {t('pages.habitat.affiliate.cycles.cycle.filteredApplications')}{' '}
            {applicationsFiltered.length}
          </p>
        </div>
      </div>
      <div className={`${style.titleContainer}`}>
        <GoBack />
        <span className={`theme-headline-medium ${style.title}`}>
          {t('pages.habitat.affiliate.cycles.cycle.title')}
        </span>
      </div>
      <div className={`${style.tableOptions}`}>
        <div className={`${style.resultsContainer}`}>
          <span className="theme-subtitle-s2">
            {t('pages.habitat.affiliate.cycles.cycle.table.title')}
          </span>
          <ResultsCounter number={applicationsCompleted.length} />
        </div>
        <div className={`${style.options}`}>
          <div className={`${style.suboptions}`}>
            <ApplicationLinkModal
              open={linkModal}
              onClickClose={() => setLinkModal(false)}
              onClickCopyLink={() => {
                handleCopyToClipboard({ cycleId: cycle.id, pathname });
              }}
              cycleName={cycle.name || 'cycle'}
              rootFormName={rootForm.name || 'rootForm'}
            />
            {/* Translate */}
            <Menu
              trigger={
                <MenuButton variation="primary" disabled={downloadingCSV}>
                  {downloadingCSV ? (
                    <Loader />
                  ) : (
                    <Flex alignItems="center">
                      Actions
                      <MdOutlineBuild size="20px" />
                    </Flex>
                  )}
                </MenuButton>
              }
              menuAlign="end"
              marginBlockStart="8px"
            >
              <MenuItem
                justifyContent="space-between"
                onClick={handleAddNewApplicationOnClick}
                gap="8px"
              >
                {t('pages.habitat.affiliate.cycles.cycle.newApplication')}
                <MdOutlineAdd />
              </MenuItem>
              <MenuItem
                justifyContent="space-between"
                onClick={() => setFilterModal(true)}
                gap="8px"
              >
                {t('pages.habitat.affiliate.cycles.cycle.filter')}
                <MdOutlineFilterList />
              </MenuItem>
              <MenuItem
                justifyContent="space-between"
                onClick={handleDownloadIncompleteCSV}
                gap="8px"
              >
                {t('pages.habitat.affiliate.cycles.cycle.report')}
                <MdOutlineSaveAlt />
              </MenuItem>
              <MenuItem
                justifyContent="space-between"
                onClick={() => {
                  setLinkModal(true);
                }}
                gap="8px"
              >
                {t('pages.habitat.affiliate.cycles.cycle.applicantLink')}
                <MdOutlineLink size="24px" />
              </MenuItem>
              <MenuItem
                justifyContent="space-between"
                onClick={() => navigate('test-application')}
                gap="8px"
              >
                {t('pages.habitat.affiliate.cycles.cycle.testApplication')}
                <MdOutlineScience />
              </MenuItem>
            </Menu>
          </div>
          {filterModal && (
            <Filters
              filters={filters}
              setFilters={(data) => setFilters(data)}
              close={() => setFilterModal(false)}
              customStatuses={habitat?.props?.customStatus || []}
            />
          )}
          <NewApplicationModal
            open={newApplicationOpen}
            onClose={handleOnCloseNewApplicationModal}
            cycle={cycle}
            setTrigger={setTrigger}
          />
        </div>
      </div>
      <StatusModal
        open={statusModalOpen}
        onClose={handleOnCloseStatusModal}
        setTrigger={setTrigger}
      />
      <TableWithPaginator
        headers={[
          {
            id: 'name',
            value: t('pages.habitat.affiliate.cycles.cycle.table.name'),
            width: '100%',
          },
          {
            id: 'type',
            value: t('pages.habitat.affiliate.cycles.cycle.table.type'),
          },
          {
            id: 'dateSubmitted',
            value: t(
              'pages.habitat.affiliate.cycles.cycle.table.dateSubmitted'
            ),
          },
          {
            id: 'reviewStatus',
            value: t('pages.habitat.affiliate.cycles.cycle.table.reviewStatus'),
          },
          {
            id: 'customStatus',
            value: (
              <span
                className={`${style.reviewStatus}`}
                onClick={handleStatusOnClick}
                aria-hidden="true"
              >
                {t('pages.habitat.affiliate.cycles.cycle.table.customStatus')}
              </span>
            ),
          },
          {
            id: 'view',
            value: t('pages.habitat.affiliate.cycles.cycle.table.view'),
          },
        ]}
        data={applicationsCompleted.map((application, index) => {
          const applicationProps = application.props as unknown as {
            name: string;
          };
          return {
            id: index,
            cells: [
              {
                id: 'name',
                value:
                  application.type === ApplicationTypes.ONLINE ? (
                    <Username application={application} />
                  ) : (
                    applicationProps?.name || ''
                  ),
              },
              {
                id: 'type',
                value: stringToHumanReadable(application.type),
              },
              {
                id: 'dateSubmitted',
                value: convertDateYYYYMMDDtoDDMMYYYY(application.submittedDate),
              },
              {
                id: 'reviewStatus',
                value: application.reviewStatus && (
                  <div className={`${style.statusContainer}`}>
                    <StatusChip status={application.reviewStatus} />
                  </div>
                ),
              },
              {
                id: 'customStatus',
                value: (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <DropdownMenu
                      className={`${style.customStatusSelect}`}
                      variation="small"
                      value={application.customStatus || ''}
                      onChange={(event) =>
                        handleUpdateApplicationStatus(
                          application.id,
                          event.currentTarget.value
                        )
                      }
                    >
                      {[
                        DEFAULT_REVIEW_STATUS,
                        ...(habitat ? habitat.props.customStatus || [] : []),
                      ].map((selectedStatus) => (
                        <option key={selectedStatus} value={selectedStatus}>
                          {selectedStatus}
                        </option>
                      ))}
                    </DropdownMenu>
                  </div>
                ),
              },
              {
                id: 'view',
                value: (
                  <div className={style.openButtonContainer}>
                    <Link to={application.id}>
                      <CustomButton
                        className={style.openButton}
                        variation="text-only"
                      >
                        <MdOutlineOpenInNew
                          size="24px"
                          color="var(--amplify-colors-neutral-90)"
                        />
                      </CustomButton>
                    </Link>
                  </div>
                ),
              },
            ],
          };
        })}
      />
    </div>
  );
};

export default AffiliateCycleApplications;
