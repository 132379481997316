import React, { useCallback, useEffect, useState } from 'react';
import FormLayout from 'components/FormLayout';
import { Form, Wizard } from '@formio/react';
import { useRouteLoaderData } from 'react-router-dom';
import { RootForm, TestCycle } from 'models';
import { useTranslation } from 'react-i18next';
import useHabitat from 'hooks/utils/useHabitat';
import { isEmpty } from 'lodash';
import useAsync from 'hooks/utils/useAsync';
import { Options } from '@formio/react/lib/components/Form';
import { Status } from 'utils/enums';
import CustomButton from 'components/CustomButton';
import style from './AffiliateApplicationTest.module.css';

const FORMIO_URL = process.env.REACT_APP_FORMIO_URL;

const AffiliateTestApplication = () => {
  const [formReady, setFormReady] = useState<typeof Wizard>();

  const [reviewMode, setReviewMode] = useState(false);

  const [submission, setSubmission] = useState<object>();

  const handleFormReady = (form: typeof Wizard) => setFormReady(form);

  const { cycle, formSchema, formUrl } = useRouteLoaderData('cycle') as {
    cycle: TestCycle;
    formSchema: object;
    formUrl: string;
  };

  const { rootForm } = useRouteLoaderData('rootForm') as { rootForm: RootForm };

  const { t: rootFormT, i18n } = useTranslation('rootForm');

  const { t } = useTranslation();

  const { language } = i18n;

  const { habitat } = useHabitat();

  const getData = useCallback(async (): Promise<
    | {
        data: {
          translations: Record<string, Record<string, string>>;
        };
      }
    | undefined
  > => {
    try {
      const response = await fetch(
        `${FORMIO_URL}/language/submission?data.language=${language}&data.form=${cycle?.formUrl}`
      );

      const array = await response.json();

      let translations = {};

      if (array.length > 0) {
        const { data } = array[0];
        const { translation } = data;
        Object.keys(translation).forEach((key) => {
          const newKey = key.replace(/__DOT__/g, '.').replace(/__USD__/g, '$');
          translation[newKey] = translation[key];
          if (newKey !== key) {
            delete translation[key];
          }
        });

        translations = {
          [`${language}`]: translation,
        };
      }

      return {
        data: {
          translations,
        },
      };
    } catch (error) {
      console.log('Error getting translations');
    }
  }, [cycle?.formUrl, language]);

  const { value, status } = useAsync({
    asyncFunction: getData,
  });

  useEffect(() => {
    if (rootForm.supportQuestion) {
      i18n.addResource(
        'en',
        'rootForm',
        'supportQuestion',
        rootForm.supportQuestion?.en
      );

      i18n.addResource(
        'es',
        'rootForm',
        'supportQuestion',
        rootForm.supportQuestion?.es || rootForm.supportQuestion?.en
      );
    }

    return () => {
      i18n.removeResourceBundle('en', 'rootForm');
      i18n.removeResourceBundle('es', 'rootForm');
    };
  }, [rootForm, i18n, t]);

  const options = {
    additional: {
      habitat,
      openCycle: cycle,
    },
    language: isEmpty(value?.data.translations) ? 'en' : language,
    i18n: value?.data.translations,
    readOnly: reviewMode,
    flatten: reviewMode,
  } as Options;

  if (status === Status.PENDING || !value) {
    return null;
  }

  return reviewMode ? (
    <div className={`${style.formContainer}`} style={{ padding: '2rem 0rem' }}>
      <Form
        key={`review-${language}`}
        url={formUrl}
        form={formSchema}
        options={options}
        submission={submission}
        onSubmit={(newSubmission: object) => {
          setSubmission(newSubmission);
          setReviewMode(true);
        }}
      />
      <div className={`${style.buttonContainer}`}>
        <CustomButton
          onClick={() => {
            setReviewMode(false);
            setFormReady(undefined);
          }}
        >
          {t('pages.habitat.affiliate.cycles.cycle.applicationTest.goBack')}
        </CustomButton>
      </div>
    </div>
  ) : (
    <FormLayout
      formReady={formReady}
      cycle={cycle}
      serializedHelpContent={rootFormT('supportQuestion')}
      deactivatePosthog
    >
      <div
        className={`${style.formContainer}`}
        style={{ padding: '2rem 1rem' }}
      >
        <Form
          key={`real-${language}`}
          url={formUrl}
          form={formSchema}
          options={options}
          submission={submission}
          formReady={handleFormReady}
          onSubmit={(newSubmission: object) => {
            setSubmission(newSubmission);
            setReviewMode(true);
          }}
        />
      </div>
    </FormLayout>
  );
};

export default AffiliateTestApplication;
