import authenticator from './authenticator';
import button from './button';
import input from './input';
import field from './field';
import fieldcontrol from './fieldcontrol';
import loader from './loader';

const components = {
  authenticator,
  button,
  input,
  field,
  fieldcontrol,
  loader,
};

export default components;
