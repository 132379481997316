const faqs = [
  {
    key: 'demo-item-1',
    question: 'What is Habitat App?',
    answer:
      'HabitatApp is the all-in-one platform for Habitat for Humanity affiliates. Currently, affiliates can conduct Homeownership and Critical Home Repair applications or pre-screens through Habitat App. Applications are totally customizable.',
  },
  {
    key: 'demo-item-8',
    question: 'Can I test Habitat App out before paying?',
    answer:
      'Yes! We offer all affiliates a 1 month free trial where they can try out Habitat App.',
  },
  {
    key: 'device',
    question: 'Does Habitat App only work on my laptop?',
    answer:
      'Habitat App is built to function in any electronic device with internet access.',
  },
  {
    key: 'demo-item-2',
    question: 'Is Habitat App customizable?',
    answer:
      'Yes! Habitat App is totally customizable. Affiliates can edit their applications, reports, analytics and more.',
  },
  {
    key: 'demo-item-3',
    question: 'Can Habitat App integrate with other software?',
    answer:
      'Yes! Habitat App can integrate with almost any other software to allow a smooth operation for your affiliate.',
  },
  {
    key: 'demo-item-4',
    question: 'How can I gain access to Habitat App?',
    answer:
      'To get access, sign up to our waitlist now. We will contact you as soon as possible.',
  },
  {
    key: 'secure',
    question: 'Is data stored securely in Habitat App?',
    answer:
      'All data is securely stored in Amazon Web Services servers, physically located in Virginia. We also implement thorough verification measures to assure only the users you verify can access your data.',
  },
  {
    key: 'prescreen',
    question:
      'What if an applicant wants to submit a paper application while I use Habitat App?',
    answer:
      'In Habitat App, you can upload paper application records to centralize your application database, and manage your decision making in one place.',
  },
  {
    key: 'future',
    question: 'Is Habitat App only an online application system?',
    answer:
      'No. At Habitat App, we are constantly working on new features to build the all-in-one application for Habitat for Humanity affiliates. Our vision is to provide your affiliate with one app to manage volunteers, keep track of donors, report to your ASO and Habitat International, render decisions on applications and more!',
  },
];

export default faqs;
