import React, { useRef, useState } from 'react';
import { Alert } from '@aws-amplify/ui-react';
import CustomButton from 'components/CustomButton';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import styles from './ApplicationLinkModal.module.css';

interface IApplicationLinkModalProps {
  open: boolean;
  onClickCopyLink: () => void;
  onClickClose: () => void;
  cycleName: string;
  rootFormName: string;
}

const ApplicationLinkModal = ({
  open,
  onClickClose,
  onClickCopyLink,
  cycleName,
  rootFormName,
}: IApplicationLinkModalProps) => {
  const { t } = useTranslation();

  const timeoutId = useRef<NodeJS.Timeout>();

  const [alerts, setAlerts] = useState<number[]>([]);

  const handleOnClickCopyLink = () => {
    onClickCopyLink();
    setAlerts((prevAlerts) => [...prevAlerts, Date.now()]);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => setAlerts([]), 5000);
  };

  return (
    <Modal
      title={t(
        'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.title'
      )}
      open={open}
      onClickClose={onClickClose}
      width="550px"
    >
      <div className={styles.container}>
        <p className={styles.text}>
          <span>
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.text.1'
            )}
            <b>
              {t(
                'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.copy'
              )}
            </b>
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.text.2'
            )}
            <b>{cycleName}</b>
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.text.3'
            )}
            <b>{rootFormName}</b>
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.text.4'
            )}
          </span>
          <br />
          <br />
          <span>
            <b>
              {t(
                'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.text.5'
              )}
            </b>
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.text.6'
            )}
            <b>{cycleName}</b>
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.text.7'
            )}
          </span>
          <br />
          <br />
          <span>
            <b>
              {t(
                'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.text.8'
              )}
            </b>
          </span>
        </p>
        {alerts.at(-1) && (
          <Alert key={alerts.at(-1)} variation="success" isDismissible>
            Link copied!
          </Alert>
        )}
        <div className={styles.buttonsContainer}>
          <CustomButton
            type="button"
            variation="secondary"
            onClick={onClickClose}
          >
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.cancel'
            )}
          </CustomButton>
          <CustomButton
            type="button"
            variation="primary"
            onClick={handleOnClickCopyLink}
          >
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.applicationLinkModal.copy'
            )}
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicationLinkModal;
