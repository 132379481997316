import { Components } from 'formiojs';
import SaveButton from './SaveButton';
import City from './City';
import Review from './Review';
import Pages from './Pages';

Components.setComponents({
  saveButton: SaveButton,
  city: City,
  review: Review,
  pages: Pages,
});
