import { Outlet, useLocation, useLoaderData } from 'react-router-dom';
import CheckMaintenance from 'layouts/Maintenance/CheckMaintenance';
import useHabitat from 'hooks/utils/useHabitat';
import { useEffect } from 'react';
import { Habitat } from 'models';

const HabitatLayout = () => {
  const { habitat } = useLoaderData() as { habitat: Habitat };

  const { setHabitat } = useHabitat();

  const { pathname } = useLocation();

  useEffect(() => {
    if (
      pathname.endsWith('/goldsboro-wayne') ||
      pathname.endsWith('/goldsboro-wayne/')
    ) {
      window.location.href =
        'https://form.habitat-app.org/jcxuakumlexxzla/manage/view/#/form/goldsboro-wayne-pre-application-1-0-0?header=0&reset=1&theme=cerulean&host=form.habitat-app.org&protocol=https';
    }
  }, [pathname]);

  useEffect(() => setHabitat(habitat), [habitat, setHabitat]);

  return (
    <CheckMaintenance>
      <Outlet />
    </CheckMaintenance>
  );
};

export default HabitatLayout;
